import scrollToElement from "scroll-to-element"

export const handleLinkClick = (e, target, callback) => {
  if (typeof window !== 'undefined') {
    if (window.location.pathname === '/') {
      if(callback) {
        callback()
      }
      if (e) e.preventDefault()
      scrollToElement(target, {
        offset: -90,
        ease: 'linear',
        duration: 300
      })
    }
  }
}