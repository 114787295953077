import styled from "styled-components"
import { breakpoints } from "../../assets/contants/breakpoints"
import { colors } from "../../assets/contants/colors"
import AnchorLink from "react-anchor-link-smooth-scroll"


export const StyledHeader = styled.header`
  background: ${colors.blue};
  height: 92px;
  display: flex;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 100;
  width: 100%;
  box-shadow: 0 0px 10px 0 rgba(0,0,0,0.25);
`

export const MenuToggle = styled.img`
    display: none;
    cursor: pointer;
    @media (max-width: ${breakpoints.md}) {
       display: block;
    }
`

export const MenuList = styled.div`
    list-style-type: none;
    display: flex;
  
    li {
        margin-left: 7px;
        margin-right: 7px;
        cursor: pointer;
        padding: 6px;
        
        a {
          color: ${colors.default};
          text-decoration: none;
        }
    }
  
    @media (max-width: ${breakpoints.md}) {
        display: block;
        position: fixed;
        padding-top: 80px;
        top: 90px;
        left: 0;
        background: #fff;
        z-index: 100;
        width: 100vw;
        height: 100vh;
        text-transform: uppercase;
        text-align: center;
        li {
            margin-bottom: 10px;
        }
    }
    
    @media (min-width: ${breakpoints.md}) and (max-width: ${breakpoints.lg}) {
       font-size: 13px;
    }
        
`


export const RegisterButton = styled.button`
  cursor: pointer;
  width: 208px;
  height: 38px;
  border-radius: 15px;
  border: none;
  background-color: ${colors.default};
  color: ${colors.white};
  font-size: 14px;
  font-weight: bold;
  
  .register-link {
    color: ${colors.white};
    text-decoration: none;
  }
`

export const Logo = styled.img`
cursor: pointer;
    @media (max-width: ${breakpoints.sm}) {
       max-width: 230px;
    } 
`