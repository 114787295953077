import React from "react"
import { MenuWrapper, Menu, Copyright, Logo, List } from "./Footer.styles"
import logo from "../../assets/images/logo3.png"
import {Link} from "gatsby";
import { handleLinkClick } from "../../helpers/scrollToSection"

export const Footer = () => {
  return (
    <div>
      <MenuWrapper className="d-flex align-items-center justify-content-between">

        <Menu className="container d-flex align-items-center justify-content-between">
          <a href="/">
            <Logo src={logo} alt="logo2"/>
          </a>
          <List>
            <li>
              <Link to={"/#news"} onClick={(e) => handleLinkClick(e,'#news')}>Aktualności</Link>
            </li>
            <li>
              <Link to={"/#offer"} onClick={(e) => handleLinkClick(e, '#offer')}>Oferta badania</Link>
            </li>
            <li>
              <Link to={"/#pricing"} onClick={(e) => handleLinkClick(e, '#pricing')}>Cennik</Link>
            </li>
            <li>
              <Link to={"/#gallery"} onClick={(e) => handleLinkClick(e, '#gallery')}>Galeria</Link>
            </li>
            <li>
              <Link to={"/#contact"} onClick={(e) => handleLinkClick(e, "#contact")}>Kontakt</Link>
            </li>
          </List>
        </Menu>
      </MenuWrapper>
      <Copyright>
        <p className="container text-center"><span>
          © Specjalistyczny Gabinet Okulistyczny Agnieszka Skalska-Dziobek 2012
        </span>
          <span className="ml-1 mr-1">·</span>
          <Link to="/rodo">Rodo</Link>
        </p>
      </Copyright>
    </div>
  )
}

export default Footer