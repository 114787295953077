import React, { useState, useEffect } from "react"
import logo from "../../assets/images/logo.svg"
import menu from "../../assets/images/menu.svg"
import close from "../../assets/images/close.svg"
import {Link} from "gatsby";
import { MenuToggle, MenuList, StyledHeader, RegisterButton, Logo } from "./Navbar.styles"
import {handleLinkClick} from "../../helpers/scrollToSection"

function Navbar() {

  const [menuVisible, toggleMenu] = useState(false)

  const toggleMenuState = () => {
   toggleMenu(!menuVisible)
  }

  return (
    <StyledHeader>
      <div className="container d-flex align-items-center justify-content-between">
        <a href="/">
          <Logo src={logo} alt="logo"/>
        </a>
        <div>
          <MenuList className={`${menuVisible ? "" : "hidden-md-down"}`}>
            <li>
              <Link to={"/#news"} onClick={(e) => handleLinkClick(e,'#news', toggleMenuState)}>Aktualności</Link>
            </li>
            <li>
              <Link to={"/#offer"} onClick={(e) => handleLinkClick(e, '#offer', toggleMenuState)}>Oferta badania</Link>
            </li>
            <li>
              <Link to={"/#pricing"} onClick={(e) => handleLinkClick(e, '#pricing', toggleMenuState)}>Cennik</Link>
            </li>
            <li>
              <Link to={"/#gallery"} onClick={(e) => handleLinkClick(e, '#gallery', toggleMenuState)}>Galeria</Link>
            </li>
            <li>
              <Link to={"/#contact"} onClick={(e) => handleLinkClick(e, "#contact", toggleMenuState)}>Kontakt</Link>
            </li>
            <li className="hidden-md-up">
              <RegisterButton onClick={(e) => handleLinkClick(e, "#online-registration", toggleMenuState)}>Rejestracja online</RegisterButton>
            </li>
          </MenuList>
        </div>
        <RegisterButton className="hidden-md-down" onClick={(e) => handleLinkClick(e, "#online-registration", toggleMenuState)}>
          <Link to={"/#online-registration"} className="register-link">Rejestracja online</Link>
        </RegisterButton>
        <MenuToggle src={menuVisible ? close : menu} className="" onClick={(e) => toggleMenuState(e)}/>
      </div>
    </StyledHeader>
  )
}

export default Navbar
